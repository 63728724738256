import React from 'react';
import { TimerCircle } from './TimerCircle';
import { TimerControls } from './TimerControls';
import { TimerInputs } from './TimerInputs';
import { useCountdownTimer } from '../hooks/useCountdownTimer';
import { Button } from "@/components/ui/button"

const CountdownTimer = () => {
  const {
    timers,
    currentTimerIndex,
    timeLeft,
    isRunning,
    startTimer,
    pauseTimer,
    resetTimers,
    handleTimerChange,
    addTimer,
    removeTimer,
  } = useCountdownTimer();

  return (
    <div className="flex flex-col items-center space-y-8">
      <TimerCircle
        timers={timers}
        currentTimerIndex={currentTimerIndex}
        timeLeft={timeLeft}
      />
      <TimerControls
        startTimer={startTimer}
        pauseTimer={pauseTimer}
        resetTimers={resetTimers}
        isRunning={isRunning}
      />
      <TimerInputs
        timers={timers}
        handleTimerChange={handleTimerChange}
        removeTimer={removeTimer}
      />
      <Button onClick={addTimer}>Add Timer</Button>
    </div>
  );
};

export default CountdownTimer;