import React from 'react';

const CIRCLE_RADIUS = 60;
const CIRCLE_CIRCUMFERENCE = 2 * Math.PI * CIRCLE_RADIUS;
const TIMER_SIZE = 130; // Size of each timer circle
const TIMER_MARGIN = 16; // Margin between timer circles (4 * 4 for space-x-4)
const TIMER_TOTAL_WIDTH = TIMER_SIZE + TIMER_MARGIN; // Total width including margin

export const TimerCircle = ({ timers, currentTimerIndex, timeLeft }) => {
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const calculateCircleProgress = (timerIndex) => {
    if (timerIndex === currentTimerIndex) {
      const totalTime = timers[currentTimerIndex];
      const progress = (totalTime - timeLeft) / totalTime;
      return CIRCLE_CIRCUMFERENCE * progress;
    }
    return timerIndex < currentTimerIndex ? CIRCLE_CIRCUMFERENCE : 0;
  };

  const calculateColor = (timerIndex) => {
    if (timerIndex === currentTimerIndex) {
      const progress = timeLeft / timers[currentTimerIndex];
      if (progress > 0.75) return 'rgb(0, 255, 0)'; // Green
      if (progress > 0.5) return 'rgb(255, 255, 0)'; // Yellow
      if (progress > 0.25) return 'rgb(255, 165, 0)'; // Orange
      return 'rgb(255, 0, 0)'; // Red
    }
    return timerIndex < currentTimerIndex ? 'rgb(0, 255, 0)' : 'rgb(200, 200, 200)';
  };

  return (
    <div className="relative w-full h-[200px] flex items-center justify-center overflow-hidden">
      <div 
        className="flex justify-center items-center space-x-4 transition-transform duration-300 ease-in-out"
        style={{
          transform: `translateX(calc(50% - ${(currentTimerIndex + 0.5) * TIMER_TOTAL_WIDTH}px))`,
        }}
      >
        {timers.map((timer, index) => (
          <div 
            key={index} 
            className={`relative transition-all duration-300 ${
              index === currentTimerIndex ? 'scale-125 z-10' : 'scale-100 z-0'
            }`}
          >
            <svg width={TIMER_SIZE} height={TIMER_SIZE} viewBox={`0 0 ${TIMER_SIZE} ${TIMER_SIZE}`}>
              <circle
                cx={TIMER_SIZE / 2}
                cy={TIMER_SIZE / 2}
                r={CIRCLE_RADIUS}
                fill="none"
                stroke="#e5e7eb"
                strokeWidth="6"
              />
              <circle
                cx={TIMER_SIZE / 2}
                cy={TIMER_SIZE / 2}
                r={CIRCLE_RADIUS}
                fill="none"
                stroke={calculateColor(index)}
                strokeWidth="6"
                strokeDasharray={CIRCLE_CIRCUMFERENCE}
                strokeDashoffset={calculateCircleProgress(index)}
                transform={`rotate(-90 ${TIMER_SIZE / 2} ${TIMER_SIZE / 2})`}
                strokeLinecap="round"
                style={{ transition: 'stroke 0.5s ease-in-out' }}
              />
            </svg>
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">
              <div className={`font-bold ${index === currentTimerIndex ? 'text-2xl' : 'text-lg'}`}>
                {index === currentTimerIndex ? formatTime(timeLeft) : formatTime(timer)}
              </div>
              <div className="text-xs">Timer {index + 1}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};