import { useState, useEffect, useRef } from 'react';

export const useCountdownTimer = () => {
  const defaultTimers = [10, 5, 5, 10, 5, 5, 10, 5, 5, 10, 5, 5, 10, 5, 5, 10, 5, 5, 30];
  const [timers, setTimers] = useState(defaultTimers);
  const [currentTimerIndex, setCurrentTimerIndex] = useState(0);
  const [timeLeft, setTimeLeft] = useState(timers[0]);
  const [isRunning, setIsRunning] = useState(false);

  const requestRef = useRef();
  const previousTimeRef = useRef();
  const startTimeRef = useRef();

  const animate = (time) => {
    if (previousTimeRef.current !== undefined) {
      const deltaTime = time - previousTimeRef.current;
      const elapsedTime = (time - startTimeRef.current) / 1000;
      const newTimeLeft = Math.max(timers[currentTimerIndex] - elapsedTime, 0);
      
      setTimeLeft(newTimeLeft);

      if (newTimeLeft <= 0) {
        if (currentTimerIndex < timers.length - 1) {
          setCurrentTimerIndex((prevIndex) => prevIndex + 1);
          startTimeRef.current = time;
        } else {
          setIsRunning(false);
          return;
        }
      }
    } else {
      startTimeRef.current = time;
    }

    previousTimeRef.current = time;
    requestRef.current = requestAnimationFrame(animate);
  };

  useEffect(() => {
    if (isRunning) {
      requestRef.current = requestAnimationFrame(animate);
    } else {
      cancelAnimationFrame(requestRef.current);
    }
    return () => cancelAnimationFrame(requestRef.current);
  }, [isRunning, currentTimerIndex]);

  useEffect(() => {
    setTimeLeft(timers[currentTimerIndex]);
  }, [currentTimerIndex, timers]);

  const startTimer = () => {
    setIsRunning(true);
    previousTimeRef.current = undefined;
  };

  const pauseTimer = () => {
    setIsRunning(false);
  };

  const resetTimers = () => {
    setIsRunning(false);
    setCurrentTimerIndex(0);
    setTimeLeft(timers[0]);
    previousTimeRef.current = undefined;
  };

  const handleTimerChange = (index, value) => {
    const newTimers = [...timers];
    newTimers[index] = Math.max(parseInt(value, 10) || 0, 0);
    setTimers(newTimers);
    if (index === currentTimerIndex) {
      setTimeLeft(newTimers[index]);
    }
  };

  const addTimer = () => {
    setTimers([...timers, 30]); // Add a new timer with 30 seconds default
  };

  const removeTimer = (index) => {
    if (timers.length > 1) {
      const newTimers = timers.filter((_, i) => i !== index);
      setTimers(newTimers);
      if (index <= currentTimerIndex) {
        setCurrentTimerIndex(Math.max(currentTimerIndex - 1, 0));
      }
      setTimeLeft(newTimers[Math.min(currentTimerIndex, newTimers.length - 1)]);
    }
  };

  return {
    timers,
    currentTimerIndex,
    timeLeft,
    isRunning,
    startTimer,
    pauseTimer,
    resetTimers,
    handleTimerChange,
    addTimer,
    removeTimer,
  };
};