import React from 'react';
import { Button } from "@/components/ui/button"

export const TimerControls = ({ startTimer, pauseTimer, resetTimers, isRunning }) => {
  return (
    <div className="flex space-x-2">
      <Button onClick={startTimer} disabled={isRunning}>Start</Button>
      <Button onClick={pauseTimer} disabled={!isRunning}>Pause</Button>
      <Button onClick={resetTimers}>Reset All</Button>
    </div>
  );
};