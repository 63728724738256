import React from 'react';
import { Input } from "@/components/ui/input"
import { Button } from "@/components/ui/button"
import { X } from "lucide-react"

export const TimerInputs = ({ timers, handleTimerChange, removeTimer }) => {
  return (
    <div className="flex flex-col space-y-2 max-h-60 overflow-y-auto p-2">
      {timers.map((timer, index) => (
        <div key={index} className="flex items-center space-x-2">
          <Input
            type="number"
            value={timer}
            onChange={(e) => handleTimerChange(index, e.target.value)}
            className="w-20"
            min="1"
          />
          <span>seconds (Timer {index + 1})</span>
          <Button
            variant="ghost"
            size="icon"
            onClick={() => removeTimer(index)}
            disabled={timers.length === 1}
          >
            <X className="h-4 w-4" />
          </Button>
        </div>
      ))}
    </div>
  );
};