import React from 'react';
import CountdownTimer from '../components/CountdownTimer';
import * as Sentry from "@sentry/react";

const ErrorFallback = ({error}) => {
  return (
    <div className="text-center">
      <h1 className="text-2xl font-bold text-red-600">Oops! Something went wrong.</h1>
      <p className="mt-2">{error.message}</p>
    </div>
  );
};

const Index = () => {
  return (
    <Sentry.ErrorBoundary fallback={ErrorFallback}>
      <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100 py-8">
        <div className="text-center w-full max-w-4xl px-4">
          <h1 className="text-4xl font-bold mb-8">Counter Downer</h1>
          <CountdownTimer />
        </div>
      </div>
    </Sentry.ErrorBoundary>
  );
};

export default Index;